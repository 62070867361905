.header {
  padding: 20px 50px 20px 50px;
  border-bottom: 1px solid #ccc;
}

.main {
  height: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
}

.container-home-x {
  min-width: 100%;
  text-align: center;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  align-items: center; /* Căn giữa theo chiều ngang */
  justify-content: center; /* Căn giữa theo chiều dọc */
  height: 100%;
  margin-top: 20px;
}

.container-home-x .button-group-home-x {
  display: flex;
  justify-content: center; /* Căn giữa theo chiều ngang */
  align-items: center; /* Căn giữa theo chiều dọc */
  flex-wrap: wrap;
  transition: opacity 0.5s ease-in-out;
  margin: 0 auto;
  overflow-y: auto;
  width: 1000px;
}

.container-home-x .button-group-home-x > * {
  width: 200px;
  gap:10px;
  height: 200px;
  text-align: center; 
}

.container-home-x .button-group-home-x.visible {
  opacity: 1;
}

.btn-homexpage {
  padding: 0 !important;
  gap: 10px;
  width: 10rem;
  height: 10rem;
  border-radius: 2rem;
  cursor: pointer;
}

.btn-homexpage img {
  width: 100%;
  max-width: 105px;
}

.item-button-home-x {
  justify-content: start;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  aspect-ratio: 1 / 1;
}

.title-button-home-x {
  color: #ffffff;
  font-size: 1.8rem;
  font-weight: normal;
  line-height: 3rem;
  font-weight: 700;
}

.btn-homexpage:hover ~ .title-button {
  color: rgb(6, 245, 34);
}

.item-button-home-x img:hover {
  opacity: 0.5;
}

.toggle-language {
  align-self: center;
  width: 150px;
}

.icon-button-xmed {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 10px;
  right: 18px;

  .icon {
      color: #ffffff;
      font-size: 30px;
      &:hover {
          color: #b3abab
      }
  }
}
.filter-buttons-container {
  display: flex;
  justify-content: center;
  .filter-buttons {
    min-width: 1000px;
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 3.25rem;
    .icon-filter {
      width: 3.8rem;
      cursor: pointer;
    }
  }
}


.filter-buttons button {
  border: 2px solid #ffffff80;
  border-radius: 4rem;
  background: transparent;
  color: white;
  padding: 12px 20px;
  font-size: 1.8rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.filter-buttons button:hover {
  background: #7491f8 ;
  transform: scale(1.05);
}

.filter-buttons button.active {
  color: #ffffff !important;
  font-weight: 700;
  transform: scale(0.95);
  border: 2px solid #ffffff;
}

.pagination-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 15px 0;
}

.btn-pagination {
  width: 20px;
  height: 5px;
  background-color: #fff;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.3s ease width;
  background: #ccc;
}

.btn-pagination.active {
  width: 55px;
  cursor: auto;
  background: #ffffff;
}

.pagination-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 2rem;
  .prev-button,
  .next-button {
    border: none;
    cursor: pointer;
    font-size: 70px;
    color: #fff;
  }
}

.w-148 {
  width: 148px;
}

.logo-xmed {
  width: 250px;
  position: absolute;
  top: 10px;
  left: 20px;
}

.app-name {
  font-size: 32px;
  margin-bottom: 30px;
}

.fixed-bottom-10 {
  bottom: 10px;
}

.company-name {
  font-size: 20px;
  color: #ffffff !important;
}

.footer-home-x {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
.btn-homexpage {
  width: 8rem;
  height: 8rem;
  background-color: transparent !important;
  border: none;
}
@media (max-width: 1919px) {
  .logo-xmed {
    width: 180px;
  }

  // .container-home-x .button-group-home-x {
  //   width: 63%;
  // }
  .app-name {
    font-size: 22px;
    margin-bottom: 20px;
  }
  .filter-buttons {
    gap: 1rem;
    margin-bottom: 2rem;
  }
  .filter-buttons button {
    padding: 4px 15px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .btn-homexpage img {
    width: 100%;
  }
  .item-button-home-x {
    gap: 0.8rem;
  }
  .title-button-home-x {
    font-size: 1.2rem;
    line-height: 2rem;
  }
  .pagination-buttons {
    .prev-button,
    .next-button {
      font-size: 50px;
    }
  }
  .pagination-dots {
    padding: 10px 0;
  }
  .company-name {
    font-size: 16px;
  }
}
@media (max-width: 1280px) {
  .logo-xmed {
    width: 180px;
  }
}
@media (max-width: 1024px) {
  .container-home-x {
    .button-group-home-x {
      width: 90%;
    }
  }
  .container-home-x .button-group-home-x {
    grid-template-columns: repeat(4, 1fr);
  }
  .company-name {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .container-home-x {
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .container-home-x .button-group-home-x {
    .pagination-buttons {
      width: 100%;
      transform: translateY(-50%);
      padding: 0;
    }
  }

  .container-home-x .button-group-home-x > * {
    flex: 0 1 calc(100% / 3); 
    min-width: calc(100% / 3);
    max-width: calc(100% / 3); 
    text-align: center; 
  }
  .company-name {
    font-size: 16px;
  }
}

@media (max-height: 864px) {
  .container-home-x .button-group-home-x>* {
    gap: 10px;
    height: 160px;
    text-align: center;
    width: 200px;
  }
  .btn-homexpage img {
    max-width: 95px;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .container-home-x {
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .container-home-x .button-group-home-x {
    grid-template-columns: repeat(2, 1fr);
    width: 90%;
  }
  .container-home-x .button-group-home-x > * {
    flex: 0 1 calc(100% / 2); 
    min-width: calc(100% / 2);
    max-width: calc(100% / 2); 
    text-align: center; 
  }
  .pagination-buttons {
    width: 100%;
    transform: translateY(-50%);
    padding: 0;
    .prev-button,
    .next-button {
      font-size: 40px;
    }
  }
  .company-name {
    font-size: 14px;
  }

}
